import en from "../lang/en";
import es from "../lang/es";
import it from "../lang/it";
import nl from "../lang/nl";
import ru from "../lang/ru";
import tr from "../lang/tr";
import de from "../lang/de";
import fa from "../lang/fa";

import Vue from "vue";
import store from "@/store";
import VueI18n from "vue-i18n";

const supportedLocales = ["de", "en", "es", "it", "nl", "ru", "tr", "fa"];

const isLocaleSupported = (locale) => supportedLocales.includes(locale);

const getLocaleFromURL = () => {
  const localePattern = /^\/(de|en|es|it|nl|ru|tr|fa)(\/|$)/;
  const match = window.location.pathname.match(localePattern);
  return match && isLocaleSupported(match[1]) ? match[1] : null;
};

const getLocaleFromCookie = () => {
  const topLevelDomain = ".coinfuze.com";
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => {
      const [key, value] = row.split("=");
      return key === "locale" && value && value.endsWith(topLevelDomain);
    })
    ?.split("=")[1];
  return cookieValue && isLocaleSupported(cookieValue)
    ? decodeURIComponent(cookieValue)
    : null;
};

const getStoreLocale = () => {
  const storeLocale = store.getters["lang/locale"];
  return storeLocale && isLocaleSupported(storeLocale) ? storeLocale : null;
};

const getBrowserLocale = () => {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) return undefined;

  const locale = navigatorLocale.trim().split("-").shift();
  return isLocaleSupported(locale) ? locale : null;
};

const defaultLanguage = getLocaleFromURL()
  ? getLocaleFromURL()
  : getLocaleFromCookie()
  ? getLocaleFromCookie()
  : getStoreLocale()
  ? getStoreLocale()
  : getBrowserLocale()
  ? getBrowserLocale()
  : "en";

store.dispatch("lang/setLocale", defaultLanguage);

const messages = {
  de,
  en,
  es,
  it,
  nl,
  ru,
  tr,
  fa,
};

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: "en",
  messages: messages,
});

export default i18n;

<template>
  <header class="header" id="header">
    <div class="container d-flex align-items-center justify-content-between">
      <span
        class="d-block header-logo"
        @click="selectMenu('home')"
        style="cursor: pointer">
        <img class="d-block" src="../assets/images/logo.svg" alt="coinfuze" />
        <!-- <img class="d-block" src="@/assets/images/logo.svg" alt="coinfuze" /> -->
      </span>
      <nav class="header-nav" :class="{ active: isOpen }">
        <div class="sh-line d-xl-none"></div>
        <div class="sh-shadow d-xl-none" style="--shadow-color: #3baaac"></div>
        <div
          class="header-nav-content flex-fill align-items-center d-flex flex-column d-xl-block">
          <div class="header-nav-footer d-xl-none container">
            <ul class="d-flex align-items-center header-socials">
              <li
                v-for="(contact, idx) in contactItems"
                :key="idx + contact.link">
                <a :href="contact.link" target="_blank">
                  <component :is="contact.icon" />
                </a>
              </li>
            </ul>
            <div
              class="header-lang"
              @click="toggleLangMenu"
              :class="{ active: showLang }"
              v-click-outside="() => (this.showLang = false)">
              <div class="header-lang-current">
                {{ locale }}
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.5 6.75L9 11.25L13.5 6.75"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
              <div class="header-lang-items" v-if="showLang">
                <span
                  v-for="(value, key) in availableLocales"
                  :key="key"
                  class="header__link"
                  style="cursor: pointer"
                  @click.prevent="setLocale(value)">
                  {{ value }}
                </span>
              </div>
            </div>
          </div>
          <ul class="header-nav-list d-flex align-items-center flex-wrap">
            <li
              @click="selectMenu(item.routerName)"
              v-for="(item, idx) in menuItems"
              :key="`${idx}${item.label}`"
              :class="{
                'active-link sh-tag-shadow': item.routerName === $route.name,
              }">
              <span>{{ item.label }}</span>
            </li>
            <li>
              <a
                class="menu-item"
                href="https://faq.coinfuze.com/"
                target="_blank"
                >{{ $t("header-item-6") }}</a
              >
            </li>
            <li>
              <a
                class="menu-item"
                href="https://docs.coinfuze.com/"
                target="_blank"
                >{{ $t("header-item-7") }}</a
              >
            </li>
          </ul>
          <a
            class="bt bt-small bt-gr bt-gr-ins d-xl-none"
            :href="`https://ca.coinfuze.com/auth/login?locale=${locale}`"
            target="_blank">
            <span>{{ $t("header-btn-1") }}</span>
          </a>
        </div>
      </nav>
      <div class="header-actions d-flex align-items-center">
        <div class="header-actions-item">
          <div
            class="header-lang d-none d-xl-block"
            :class="{ active: showMLang }"
            @click="toggleMLangMenu"
            v-click-outside="() => (this.showMLang = false)">
            <div class="header-lang-current">
              {{ locale }}
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.5 6.75L9 11.25L13.5 6.75"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <div class="header-lang-items" v-if="showMLang">
              <span
                v-for="(value, key) in availableLocales"
                :key="key"
                class="header__link"
                style="cursor: pointer"
                @click.prevent="setLocale(value)">
                {{ value }}
              </span>
            </div>
          </div>
        </div>
        <div class="header-actions-item d-none d-xl-block">
          <ul class="d-flex align-items-center socials">
            <li
              v-for="(contact, idx) in contactItems"
              :key="idx + contact.link">
              <a :href="contact.link" target="_blank">
                <component :is="contact.icon" />
              </a>
            </li>
          </ul>
        </div>
        <div class="header-actions-item d-none d-xl-block">
          <a
            class="bt bt-small bt-gr bt-gr-ins"
            :href="`https://ca.coinfuze.com/auth/login?locale=${locale}`"
            target="_blank">
            <span>{{ $t("header-btn-1") }}</span>
          </a>
        </div>

        <div class="header-actions-item d-xl-none test" style="width: 50px">
          <div
            class="header-toggle"
            :class="{ active: isOpen }"
            type="button"
            @click="toggleMenu">
            <span></span><span></span><span></span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
import TwitterIcon from "@/components/icons/TwitterIcon.vue";
import TelegramIcon from "@/components/icons/TelegramIcon.vue";
import DiscordIcon from "@/components/icons/DiscordIcon.vue";
import vClickOutside from "v-click-outside";

export default {
  name: "HeaderItem",
  components: {
    TwitterIcon,
    TelegramIcon,
    DiscordIcon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isOpen: false,
      showLang: false,
      showMLang: false,
      windowWidth: window.innerWidth,
      // showSmallDropdown: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    shouldShowNav() {
      return this.isOpen || this.windowWidth > 1200;
    },
    currentRouter() {
      return this.$route.path;
    },
    availableLocales() {
      const selectedLocale = this.locale;
      const allLocales = this.locales;

      return Object.keys(allLocales)
        .filter((key) => key !== selectedLocale)
        .reduce((obj, key) => {
          obj[key] = allLocales[key];
          return obj;
        }, {});
    },
    contactItems() {
      return [
        { icon: TelegramIcon, link: "https://t.me/coinfuze" },
        { icon: DiscordIcon, link: "https://discord.gg/B6MCMDR9qP" },
        { icon: TwitterIcon, link: "https://twitter.com/Coin_fuze" },
      ];
    },
    menuItems() {
      return [
        {
          label: this.$t("header-item-1"),
          routerName: "about-us",
        },
        {
          label: this.$t("header-item-2"),
          routerName: "first-step",
        },
        {
          label: this.$t("header-item-3"),
          routerName: "investing",
        },
        {
          label: this.$t("header-item-4"),
          routerName: "affiliate",
        },
        {
          label: this.$t("header-item-5"),
          routerName: "Statistic",
        },
      ];
    },
    ...mapGetters({
      locale: "lang/locale",
      locales: "lang/locales",
    }),
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    setLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$store.dispatch("lang/setLocale", locale).then(() => {
          document.documentElement.setAttribute("lang", locale);
          const currentPath = this.$route.path;

          const newPath = currentPath.replace(
            /^\/[a-zA-Z]{2}(\/|$)/,
            `/${locale}$1`
          );

          if (locale === "fa") {
            document.documentElement.setAttribute("dir", "rtl");
          } else {
            document.documentElement.removeAttribute("dir");
          }

          this.$router.push(newPath);
        });
      }
    },

    selectMenu(name) {
      if (this.$route.name === name) return;
      this.closeMenu();
      this.$router.push({ name: name });
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
      // document.body.classList.toggle("overflow-hidden");
    },
    toggleLangMenu() {
      this.showLang = !this.showLang;
    },
    toggleMLangMenu() {
      this.showMLang = !this.showMLang;
    },
    toggleSmallDropdown() {
      this.showSmallDropdown = !this.showSmallDropdown;
    },
    closeMenu() {
      this.isOpen = false;
    },
    closeHeader() {
      this.$refs.header.classList.remove("open");
      this.isOpen = false;
    },
    handleDocumentClick(event) {
      if (
        this.showDropdown &&
        this.$refs.dropdown &&
        !this.$refs.dropdown.contains(event.target)
      ) {
        this.showDropdown = false;
      }
    },
    handleClick(event) {
      if (
        this.showSmallDropdown &&
        this.$refs.smallDropdown &&
        !this.$refs.smallDropdown.contains(event.target)
      ) {
        this.showSmallDropdown = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// @import url("../styles/scss/global/_mixins.scss");
@import url("../styles/scss/global/_vars.scss");
$xxl: 1430px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

@mixin xxl {
  @media (max-width: $xxl) {
    @content;
  }
}
@mixin xl {
  @media (max-width: $xl) {
    @content;
  }
}
@mixin lg {
  @media (max-width: $lg) {
    @content;
  }
}
@mixin md {
  @media (max-width: $md) {
    @content;
  }
}
@mixin sm {
  @media (max-width: $sm) {
    @content;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.test {
  display: flex;
  justify-content: space-around;
}

a {
  cursor: pointer;
}
.header {
  padding: 1.25rem 0;
  position: relative;
  @include sm {
    padding: 1.214rem 0;
  }
  &-socials {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style-type: none;
    gap: 0.75rem;
    li {
      a {
        path {
          transition: fill ease-in-out 0.2s;
        }
        &:hover {
          path {
            fill: var(--s-blue-color);
          }
        }
      }
    }
  }
  &-logo {
    max-width: 7.875rem;
  }
  &-nav {
    margin: 0 2rem 0 2rem;

    @media screen and (min-width: 1439px) {
      margin: 0 3rem 0 5rem;
    }
    @include xl {
      position: absolute;
      width: 100vw;
      // width: 96vw;
      // height: calc(100vh - 5rem);
      height: 90vh;
      overflow-y: auto;
      top: 100%;
      left: 0;
      z-index: 4;
      margin: 0;
      background-color: var(--s-black-color);

      display: flex;
      // flex-direction: column;

      transform: translateZ(0);

      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out;
      -webkit-transition: opacity 0.3s ease-in-out;
      &.active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in-out;
        -webkit-transition: opacity 0.3s ease-in-out;
      }
    }
    @media screen and (min-width: 1200px) {
      margin: 0 0.8rem 0 0.8rem;
    }
    &-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      gap: 1.575rem;

      @media screen and (min-width: 1439px) {
        gap: 1.875rem;
      }
      @include xl {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.143rem;
        margin-top: 4rem;
        margin-bottom: 3.429rem;
      }
      @media screen and (min-width: 1200px) {
        gap: 0.8rem;
      }
      li {
        span,
        a {
          cursor: pointer;
          color: var(--s-white-color);
          text-decoration: none;
          font-size: 0.875rem;
          transition: color ease-in-out 0.2s;
          -webkit-transition: color ease-in-out 0.2s;
          @include xl {
            font-size: 1.429rem;
          }
          &.current,
          &:hover {
            color: var(--s-blue-color);
          }
        }
      }
    }

    &-content,
    &-footer {
      @include xl {
        padding: 1.643rem 1.071rem;
        box-sizing: border-box;
      }
    }
    &-footer {
      @include xl {
        // position: absolute;
        // right: 0;
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  &-actions {
    gap: 1.5rem;
    .bt {
      min-width: 6.625rem;
      @include sm {
        min-width: auto;
      }
    }
  }
  &-lang {
    position: relative;
    display: flex;
    @media screen and (min-width: 1439px) {
      margin-right: 2.5rem;
    }
    &-current {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 0.125rem;
      color: var(--s-white-color);
      cursor: pointer;
      font-size: 0.875rem;
      transition: color ease-in-out 0.2s;
      -webkit-transition: color ease-in-out 0.2s;
      path {
        transition: stroke ease-in-out 0.2s;
        -webkit-transition: stroke ease-in-out 0.2s;
      }
      &:hover {
        color: var(--s-blue-color);
        path {
          stroke: var(--s-blue-color);
        }
      }
      svg {
        position: relative;
        transition: transform ease-in-out 0.2s;
        -webkit-transition: transform ease-in-out 0.2s;
      }
    }
    &-items {
      position: absolute;
      background-color: var(--s-modal-bg);
      border: 1px solid rgba(255, 255, 255, 0.05);
      border-radius: 0.625rem;
      top: 100%;
      left: -10px;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.625rem 1rem;
      box-sizing: border-box;
      opacity: 0;
      visibility: hidden;
      transition: top ease-in-out 0.2s, opacity ease-in-out 0.2s,
        visibility ease-in-out 0.2s;
      -webkit-transition: top ease-in-out 0.2s, opacity ease-in-out 0.2s,
        visibility ease-in-out 0.2s;
      &.p-top {
        top: inherit;
        bottom: 100%;
      }
      span {
        padding: 0.18rem;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.875rem;
        color: var(--s-white-color);
        transition: color ease-in-out 0.2s;
        -webkit-transition: color ease-in-out 0.2s;
        &:hover {
          color: var(--s-blue-color);
        }
      }
    }
    &.active {
      .header-lang-current {
        svg {
          transform: rotate(180deg);
        }
      }
      .header-lang-items {
        top: calc(100% + 0.5rem);
        opacity: 1;
        visibility: visible;
        &.p-top {
          top: inherit;
          bottom: calc(100% + 0.5rem);
        }
      }
    }
  }

  &-toggle {
    width: 2.143rem;
    height: 3.714rem;
    padding: 0;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    // transition: transform ease-in-out 0.2s;
    // -webkit-transition: -webkit-transform ease-in-out 0.2s;
    will-change: transform;
    @include md {
      height: 2.714rem;
    }
    span {
      will-change: transform;
      display: block;
      height: 2px;
      width: 1.571rem;
      background-color: var(--s-white-color);
      position: absolute;
      right: 0;
      border-radius: 1rem;
      transition: transform ease-in-out 0.4s, opacity ease-in-out 0.4s,
        top ease-in-out 0.4s, bottom ease-in-out 0.4s;
      -webkit-transition: -webkit-transform ease-in-out 0.3s,
        opacity ease-in-out 0.3s, top ease-in-out 0.3s, bottom ease-in-out 0.3s;
      &:nth-child(1) {
        top: 37%;
        @include md {
          top: 30%;
        }
      }
      &:nth-child(2) {
        top: 50%;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
      }
      &:nth-child(3) {
        bottom: 37%;
        @include md {
          bottom: 30%;
        }
      }
    }
    &.active {
      span {
        will-change: transform;
        &:nth-child(1) {
          top: 50%;
          transform: translate(0, -50%) rotate(45deg);
          -webkit-transform: translate(0, -50%) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          bottom: inherit;
          top: 50%;
          transform: translate(0, -50%) rotate(-45deg);
          -webkit-transform: translate(0, -50%) rotate(-45deg);
        }
      }
    }
  }
}
.active-link {
  span {
    color: var(--s-blue-color) !important;
  }
}
</style>

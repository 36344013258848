<template>
  <div class="not-found">
    {{ $t("page-not-found") }}
  </div>
</template>
<script>
export default {
  name: "NotFound",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.not-found {
  display: flex;
  min-height: 50vh;
  text-align: center;
  align-items: center;
  justify-content: center;
}
</style>

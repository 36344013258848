import Vue from "vue";
import store from "@/store";
import router from "@/router/routes";
import i18n from "@/plugins/i18n";
import App from "./App.vue";
import VueMeta from "vue-meta";
import VueJsonLD from "vue-jsonld";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
Vue.use(VueJsonLD);

Vue.use(VueMeta, {
  keyName: "metaInfo",
});

import "@/components";
new Vue({
  i18n,
  store,
  router,
  ...App,
});

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import HomePage from "../views/HomePage";
import GuidePage from "../views/GuidePage";
import InvestingPage from "../views/InvestingPage";
import AffiliatePage from "../views/AffiliatePage";
import AboutPage from "../views/AboutPage";
import StatisticPage from "../views/StatisticPage";
import StatusPage from "../views/StatusPage";
import NotFound from "../components/NotFound";

Vue.use(VueRouter);
const routes = [
  {
    path: "*",
    component: NotFound,
  },
  {
    path: "/:locale?",
    name: "home",
    component: HomePage,
    meta: {
      script: {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "CoinFuze",
          alternateName: "Coin Fuze LLC",
          url: "https://coinfuze.com/",
          logo: "https://coinfuze.com/img/logo.60d4afae.svg",
          sameAs: [
            "https://twitter.com/Coin_fuze",
            "https://discord.gg/B6MCMDR9qP",
          ],
        }),
      },
    },
  },
  {
    path: "/:locale?/first-steps",
    name: "first-step",
    component: GuidePage,
    meta: {
      script: {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": "https://coinfuze.com/en/first-steps",
          },
          headline:
            "CoinFuze: Your First Step into the World of Innovative Investments",
          description:
            "Are you ready to invest for the future? CoinFuze provides support and guidance to make your first steps in investing confident and successful.",
          image: "https://coinfuze.com/img/step-img-1.68713cf1.png",
          author: {
            "@type": "Organization",
            name: "CoinFuze",
            url: "https://coinfuze.com/",
          },
          publisher: {
            "@type": "Organization",
            name: "CoinFuze",
            logo: {
              "@type": "ImageObject",
              url: "https://coinfuze.com/img/logo.60d4afae.svg",
            },
          },
          datePublished: "2018-01-01",
          dateModified: "2024-04-19",
        }),
      },
    },
  },
  {
    path: "/:locale?/investing",
    name: "investing",
    component: InvestingPage,
    meta: {
      script: {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          type: "application/ld+json",
          innerHTML: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://coinfuze.com/en/investing",
            },
            headline:
              "Investing in Cryptocurrencies with CoinFuze Professional Approach!",
            description:
              "Maximize your investing experience with CoinFuze, an innovative algorithmic ecosystem. Manage your portfolio easily, increase your returns with an automated system, and discover new horizons in cryptocurrency algorithms.",
            image: "https://coinfuze.com/img/investiong-img.f27d7351.webp",
            author: {
              "@type": "Organization",
              name: "CoinFuze",
              url: "https://coinfuze.com/",
            },
            publisher: {
              "@type": "Organization",
              name: "CoinFuze",
              logo: {
                "@type": "ImageObject",
                url: "https://coinfuze.com/img/logo.60d4afae.svg",
              },
            },
            datePublished: "2018-01-01",
            dateModified: "2024-04-19",
          }),
        }),
      },
    },
  },
  {
    path: "/:locale?/affiliate",
    name: "affiliate",
    component: AffiliatePage,
    meta: {
      script: {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": "https://coinfuze.com/en/affiliate",
          },
          headline: "CoinFuze Referral Programme: Invite and Earn!",
          description:
            "Be part of our success! Invite your friends to CoinFuze and receive exclusive bonuses. Our Referral Programme is designed for your growth and prosperity.",
          image: "https://coinfuze.com/img/referral-img.f7b9266c.png",
          author: {
            "@type": "Organization",
            name: "CoinFuze",
            url: "https://coinfuze.com/",
          },
          publisher: {
            "@type": "Organization",
            name: "CoinFuze",
            logo: {
              "@type": "ImageObject",
              url: "https://coinfuze.com/img/logo.60d4afae.svg",
            },
          },
          datePublished: "2018-01-01",
          dateModified: "2024-04-19",
        }),
      },
    },
  },
  {
    path: "/:locale?/about-us",
    name: "about-us",
    component: AboutPage,
    meta: {
      script: {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": "https://coinfuze.com/en/about-us",
          },
          headline:
            "History of the system, CoinFuze Team, Goals and RoadMap of the project.",
          description:
            "Learn more about the CoinFuze team, our founders and goals. We are committed to helping you achieve stable financial results with the CoinFuze automated system.",
          image: "https://coinfuze.com/img/about-img.810c56d0.webp",
          author: {
            "@type": "Organization",
            name: "CoinFuze",
            url: "https://coinfuze.com/",
          },
          publisher: {
            "@type": "Organization",
            name: "CoinFuze",
            logo: {
              "@type": "ImageObject",
              url: "https://coinfuze.com/img/logo.60d4afae.svg",
            },
          },
          datePublished: "2018-01-01",
          dateModified: "2024-04-19",
        }),
      },
    },
  },
  {
    path: "/:locale?/statistic",
    name: "Statistic",
    component: StatisticPage,
    meta: {
      script: {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": "https://coinfuze.com/en/statistic",
          },
          headline: "CoinFuze: Detailed Algorithm Statistics for Investors",
          description:
            "See the performance statistics of the individual CoinFuze trading pool. Your path to optimized and intelligent investments starts here. Statistics are constantly updated.",
          image: "",
          author: {
            "@type": "Organization",
            name: "CoinFuze",
            url: "https://coinfuze.com/",
          },
          publisher: {
            "@type": "Organization",
            name: "CoinFuze",
            logo: {
              "@type": "ImageObject",
              url: "https://coinfuze.com/img/logo.60d4afae.svg",
            },
          },
        }),
      },
    },
  },
  {
    path: "/:locale?/status",
    name: "status",
    component: StatusPage,
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (from.meta.script && from.meta.script.type === "application/ld+json") {
//     const oldScript = document.querySelector(
//       `script[type="${from.meta.script.type}"]`
//     );
//     if (oldScript) {
//       oldScript.parentNode.removeChild(oldScript);
//     }
//   }
//   if (to.meta.script) {
//     const scriptElement = document.createElement("script");
//     scriptElement.type = to.meta.script.type;
//     scriptElement.innerHTML = to.meta.script.innerHTML;
//     document.head.appendChild(scriptElement);
//   }
//   next();
// });
router.beforeEach((to, from, next) => {
  next();
  const language = store.getters["lang/locale"];
  const localesObject = store.getters["lang/locales"];

  const localesArray = Object.values(localesObject);

  const hasLocaleParam = to.path
    .split("/")
    .some((segment) => localesArray.includes(segment));

  if (!hasLocaleParam || to.params.locale !== language) {
    const newPath = `/${language}${to.path.replace(/^\/[a-zA-Z]{2}\//, "/")}`;
    next({ path: newPath });
  } else {
    next();
  }
});

export default router;

<template>
  <div class="how-content-item" id="fuze">
    <h2 class="how-content-title">{{ $t("investing-text-16") }}</h2>
    <div class="box">
      <div class="box-inner">
        <p class="mb-3">
          {{ $t("investing-text-17") }}
        </p>
        <a
          class="bt bt-gr bt-gr-ins how-content-button mb-4"
          :href="`https://docs.coinfuze.com/${locale}/daily-fuze`"
          target="_blank"
          ><span> {{ $t("investing-text-17-1") }}</span></a
        >
        <div class="how-fz">
          <div
            class="row how-fz-row flex-column-reverse flex-md-row align-items-end">
            <div class="col-md-6">
              <div class="how-fz-item">
                <h4 class="how-fz-item-title">{{ $t("investing-text-18") }}</h4>
                <div class="how-fz-item-text">
                  {{ $t("investing-text-19") }}
                </div>
              </div>
              <div class="how-fz-item">
                <h4 class="how-fz-item-title">{{ $t("investing-text-20") }}</h4>
                <div class="how-fz-item-text">
                  {{ $t("investing-text-21") }}
                </div>
              </div>
              <div class="how-fz-item">
                <h4 class="how-fz-item-title">{{ $t("investing-text-22") }}</h4>
                <div class="how-fz-item-text">
                  {{ $t("investing-text-23") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="how-fz-img">
                <img
                  v-if="locale === 'ru'"
                  src="@/assets/images/fz-img-ru.png"
                  alt="coinfuze" />
                <img v-else src="@/assets/images/fz-img.png" alt="coinfuze" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "InvestingFuze",
  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
  },
};
</script>

import Cookies from "js-cookie";
import * as types from "../mutation-types";
// import i18n from "@/plugins/i18n";
// import { BASE_API_URL } from '../mutation-types'

const lang = {
  locale: "en",
  fallbackLocale: "en",
  locales: {
    ru: "ru",
    en: "en",
    tr: "tr",
    es: "es",
    it: "it",
    de: "de",
    nl: "nl",
    fa: "fa",
  },
};

const { locale, locales } = lang;

// state
export const state = {
  locale: getLocale(locales, locale),
  locales: locales,
};

// getters
export const getters = {
  locale: (state) => state.locale,
  locales: (state) => state.locales,
  localesList: (state) => Object.keys(state.locales),
};

// mutations
export const mutations = {
  [types.SET_LOCALE](state, locale) {
    const topLevelDomain = ".coinfuze.com";
    document.cookie = `locale=${encodeURIComponent(
      locale
    )};domain=${topLevelDomain};path=/`;
    state.locale = locale;
  },
};

// actions
export const actions = {
  setLocale({ commit }, locale) {
    commit(types.SET_LOCALE, locale);
    // i18n.locale = locale;
  },
};

/**
 * @param  {String[]} locales
 * @param  {String} fallback
 * @return {String}
 */
function getLocale(locales, fallback) {
  const locale = Cookies.get("locale");
  if (Object.prototype.hasOwnProperty.call(locales, locale)) {
    return locale || "en";
  } else if (locale) {
    Cookies.remove("locale");
  }

  return fallback;
}
